<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="../../assets/image/icon/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/admin-config')"
          >
          <span class="robot-setting-title">&nbsp;&nbsp;&nbsp;옵션 관리</span>
          <v-spacer />
        </v-row>
        <v-col class="content-area">
          <h3 class="robot-option-title">Ver.{{status.version}}</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> Model Flag(초기화) </p>
            <v-select
              v-model="status.model"
              class="ma-10"
              :items="status.modelList"
              item-color="grey"
              placeholder="모델 선택"
              outlined
              dark
              style="display: inline-block; width: 100px;"
            />
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('model')" > 저장 </v-btn> </p>
          </v-col>

          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 실험적인 기능(미사용)</p>
            <input v-model="status.use_preview" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_preview')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">제어</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 GPIO 버튼활성 </p>
            <input v-model="status.use_gpio" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_gpio')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 태스크시 위치도착 반경 </p>
            <input v-model="status.location_collision_radius" placeholder="0.1 ~ 2 (단위는 미터고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('location_collision_radius')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 전진 속도 </p>
            <input v-model="status.jog_front_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_front_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 후진 속도 </p>
            <input v-model="status.jog_back_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_back_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 회전 속도 </p>
            <input v-model="status.jog_rotation_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_rotation_speed')" > 저장 </v-btn> </p>
          </v-col>

          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 컨베이어 사용 유무 </p>
            <input v-model="status.use_conveyor" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_conveyor')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 리프트 사용 유무 </p>
            <input v-model="status.use_lift" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_lift')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 앞으로 도킹 유무 </p>
            <input v-model="status.docking_front" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('docking_front')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 도킹 거리 </p>
            <input v-model="status.docking_distance" placeholder="0.1 ~ 2 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('docking_distance')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 이동 트리거 반경 </p>
            <input v-model="status.move_trigger_radius" placeholder="0.1 ~ 2 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('move_trigger_radius')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 이동 트리거 속도 </p>
            <input v-model="status.move_trigger_speed" placeholder="0.1 ~ 2 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('move_trigger_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 카메라 화면 토픽 이름 </p>
            <input v-model="status.cam_topic_name" placeholder="/usb_cam/image_raw 또는 /camera1/color/image_raw" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('cam_topic_name')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 배터리 부족 시 자동 종료 </p>
            <input v-model="status.auto_power_off" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('auto_power_off')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 배터리 부족 시 자동 도킹 </p>
            <input v-model="status.auto_docking" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('auto_docking')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">UI(상태)</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 IP 표출 유무 </p>
            <input v-model="status.use_robot_ip" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_robot_ip')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 이동 결과 표출 유무 </p>
            <input v-model="status.use_move_result" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_move_result')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> EMG 신호 표출 유무 </p>
            <input v-model="status.use_emg_signal" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_emg_signal')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 범퍼 신호 표출 유무 </p>
            <input v-model="status.use_bumper_signal" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_bumper_signal')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 서보 신호 표출 유무 </p>
            <input v-model="status.use_servo_signal" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_servo_signal')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 도킹 신호 표출 유무 </p>
            <input v-model="status.use_docking_signal" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_docking_signal')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 수화물 신호 표출 유무 </p>
            <input v-model="status.use_load_signal" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_load_signal')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 사용 전력 표출 유무 </p>
            <input v-model="status.use_system_power" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_system_power')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 시간 표출 유무 </p>
            <input v-model="status.use_system_time" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_system_time')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 속도 표출 유무 </p>
            <input v-model="status.use_speed" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> imu roll 표출 유무 </p>
            <input v-model="status.use_imu_roll" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_imu_roll')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> imu pitch 표출 유무 </p>
            <input v-model="status.use_imu_pitch" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_imu_pitch')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> imu yaw 표출 유무 </p>
            <input v-model="status.use_imu_yaw" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_imu_yaw')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">UI(페이지)</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로그인 페이지 표출 유무 </p>
            <input v-model="status.use_init_login" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_init_login')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 모드선택 페이지 표출 유무 </p>
            <input v-model="status.use_init_mode" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_init_mode')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">UI(메뉴)</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 위치 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_location" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_location')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 태스크 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_task" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_task')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 시간 트리거 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_repeat" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_repeat')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 작성 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_mapping" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_mapping')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 관리 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_map" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_map')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 설정 메뉴 표출 유무 </p>
            <input v-model="status.use_menu_setting" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_menu_setting')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">UI(버튼)</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 0번 도킹만 표출 유무 </p>
            <input v-model="status.only_home_docking" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('only_home_docking')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 메인 태스크 표출 유무 </p>
            <input v-model="status.use_main_task" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_main_task')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 메인 플랜 표출 유무 </p>
            <input v-model="status.use_main_plan" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_main_plan')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 메인 위치 이동 표출 유무 </p>
            <input v-model="status.use_main_location" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_main_location')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 메인 지도 90도 회전 표출 유무 </p>
            <input v-model="status.use_main_map_rotation" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_main_map_rotation')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 표출 유무 </p>
            <input v-model="status.use_head" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 여닫기 버튼 유무 </p>
            <input v-model="status.use_head_button_open" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_open')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 지도확장 버튼 유무 </p>
            <input v-model="status.use_head_button_void" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_void')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 서보 버튼 유무 </p>
            <input v-model="status.use_head_button_servo" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_servo')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 조그 버튼 유무 </p>
            <input v-model="status.use_head_button_jog" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_jog')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 리로드 버튼 유무 </p>
            <input v-model="status.use_head_button_reload" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_reload')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 해더 위치 추정 버튼 유무 </p>
            <input v-model="status.use_head_button_estimate" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_head_button_estimate')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 관리자 세팅 버튼 유무 </p>
            <input v-model="status.use_setting_admin" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_setting_admin')" > 저장 </v-btn> </p>
          </v-col>

          <hr/>
          <h3 class="robot-option-title">UI(지도)</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 그리드 표출 유무 </p>
            <input v-model="status.map_option_grid" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('map_option_grid')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 라이다 표출 유무 </p>
            <input v-model="status.map_option_lidar" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('map_option_lidar')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 반경 표출 유무 </p>
            <input v-model="status.map_option_radius" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('map_option_radius')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 지도 스케일바 표출 유무 </p>
            <input v-model="status.map_option_scale" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('map_option_scale')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 라이다 토픽 이름 </p>
            <input v-model="status.lidar_topic_name" placeholder="/scan 또는 /scan_merge" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('lidar_topic_name')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 라이다 x 옵셋 </p>
            <input v-model="status.lidar_offset_x" placeholder="0.0 ~ 2.0 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('lidar_offset_x')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 라이다 y 옵셋 </p>
            <input v-model="status.lidar_offset_y" placeholder="0.0 ~ 2.0 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('lidar_offset_y')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 가로 너비 </p>
            <input v-model="status.robot_width" placeholder="0.0 ~ 2.0 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('robot_width')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 세로 앞너비 </p>
            <input v-model="status.robot_top_height" placeholder="0.0 ~ 2.0 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('robot_top_height')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 로봇 세로 뒷너비 </p>
            <input v-model="status.robot_bottom_height" placeholder="0.0 ~ 2.0 (단위는 m고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('robot_bottom_height')" > 저장 </v-btn> </p>
          </v-col>

          <v-btn
            class="btn mt-9"
            color="#00BFB4"
            dark
            @click="updateSoftware"
          >
            소프트웨어 업데이트 (인터넷연결 필수)
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'AdminSetting',
  data() {
    return {
      status:{
        model: 'm',
        modelList: ['m'],
        language: 'k',
        use_preview: 0,

        // control
        servo_state: 1,
        use_docking: 1,
        docking_front: 1,
        docking_battery: 30,
        docking_battery_max: 80,
        docking_distance: 0.5,
        location_collision_radius: 0.5,
        base_speed: 0.3,
        jog_front_speed: 0.4,
        jog_back_speed: 0.2,
        jog_rotation_speed: 0.3,
        use_conveyor: 0,
        use_lift: 0,
        use_gpio: 0,
        move_trigger_radius: 0,
        move_trigger_speed: 0,
        cam_topic_name: '/camera1/color/image_raw',
        weight_obstacle: 20.0,
        auto_power_off: 1,
        auto_docking: 1,

        // ui status
        use_robot_ip: 1,
        use_move_result: 1,
        use_emg_signal: 1,
        use_bumper_signal: 1,
        use_servo_signal: 1,
        use_docking_signal: 1,
        use_load_signal: 1,
        use_system_power: 1,
        use_system_time: 1,
        use_speed: 1,
        use_imu_roll: 0,
        use_imu_pitch: 0,
        use_imu_yaw: 0,

        // ui page
        use_init_mode: 0,
        use_init_login: 0,

        // ui menu
        use_menu_location: 1,
        use_menu_task: 1,
        use_menu_repeat: 1,
        use_menu_mapping: 1,
        use_menu_map: 1,
        use_menu_setting: 1,

        // ui button
        only_home_docking: 0,
        use_main_task: 0,
        use_main_plan: 1,
        use_main_location: 1,
        use_main_map_rotation: 0,
        use_head: 1,
        use_head_button_open: 1,
        use_head_button_void: 1,
        use_head_button_servo: 1,
        use_head_button_jog: 1,
        use_head_button_reload: 1,
        use_head_button_estimate: 1,
        use_setting_admin: 1,

        // ui map
        map_option_grid: 0,
        map_option_lidar: 0,
        map_option_radius: 0,
        map_option_scale: 0,
        lidar_topic_name: '/scan',
        lidar_offset_x: 0.15,
        lidar_offset_y: 0.0,
        robot_width: 0.490,
        robot_top_height: 0.200,
        robot_bottom_height: 0.441,
      },
    }
  },
  created() {
    this.getModelList();
    this.loadData();
  },
  methods: {
    async getModelList(){
      const res = await this.$axios.get('/robot/model');
      if (res.status !== 200) return;

      this.status.modelList= res.data;
    },
    async loadData(){
      const res = await this.$axios.get('/info');
      if (res.status !== 200) return;

      const options= res.data;
      for(const option_name in options){
        this.status[option_name]= options[option_name];
      }
    },
    async saveData(name){
      const value= this.status[name];
      const res = await this.$axios.put('/robot/option', {
        option_name: name,
        option_value: value,
      });
      if (res.status === 200) await this.loadData();
    },
    async updateSoftware(){
      await this.$axios.put('/update');
      await this.$router.replace('/');
    },
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.robot-setting-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 60vh;
  text-align: center;
  overflow: auto;
}

.robot-option-title {
  padding-top: 30px;
  font-size: 2rem;
  color: white;
}

.input-setting {
  width: 30%;
  height: 80px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-setting:focus {
  outline: #9aa0ae solid;
}

.textarea {
  width: 70%;
  height: 180px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 1rem;
  color: white;
}

.textarea:focus {
  outline: #9aa0ae solid;
}

.default-robot-option-text {
  padding-left: 30px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 30%;
  font-size: 1.5rem;
  color: white;
}

.default-robot-option-text > span {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .robot-setting-title {
    font-size: 20px !important;
  }

  .input-setting {
    width: 30%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .textarea {
    width: 70%;
    height: 120px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .default-robot-option-text {
    padding-left: 30px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 30%;
    font-size: 1.0rem;
    color: white;
  }

  .default-robot-option-text > span {
    font-size: 0.7rem;
  }
}
</style>
